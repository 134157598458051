import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryService from "../services/categoryService";

const initialState = {
  categories: [],
  category: {},
  error: false,
  success: false,
  loading: false,
  message: null,
  reloadTable: false,
};

export const getCategory = createAsyncThunk("category/get", async (id, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await categoryService.getCategory(id, token);

  if(data.errors)
  return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const getAllCategories = createAsyncThunk("category/getall", async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await categoryService.getAllCategories(token);

    if(data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

    return data;
});

export const registerCategory = createAsyncThunk("category/register", async (category, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await categoryService.registerCategory(category, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const updateCategory = createAsyncThunk("category/update", async (category, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await categoryService.updateCategory(category, category.id, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const deleteCategory = createAsyncThunk("category/delete", async (id, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await categoryService.deleteCategory(id, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const userSlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
    resetError: (state) => {
      state.error = false;
    },
    resetSuccess: (state) => {
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = true;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = true;
      })
      .addCase(registerCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = false;
      })
      .addCase(registerCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reloadTable = false;
      })
      .addCase(updateCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = false;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reloadTable = false;
      })
      .addCase(getAllCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = false;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.categories = action.payload;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.category = action.payload;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { resetMessage, resetError, resetSuccess } = userSlice.actions;
export default userSlice.reducer;