//hooks
import { useAuth } from './hooks/useAuth';
import { useSelector } from "react-redux";

//components
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Dashboard from './layouts/Dashboard';

//pages
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import SalesList from './pages/SalesList/SalesList';
import ProductList from './pages/ProductList/ProductList';
import AddProducts from './pages/AddProducts/AddProducts';
import EditProducts from './pages/EditProducts/EditProducts';
import CustomerList from './pages/CustomerList/CustomerList';
import AddCustomers from './pages/AddCustomers/AddCustomers';
import EditCustomers from './pages/EditCustomers/EditCustomers';
import UserList from './pages/UserList/UserList';
import AddUser from './pages/AddUser/AddUser';
import EditUser from './pages/EditUser/EditUser';
import EditSales from './pages/EditSales/EditSales';

const App = () => {
  
  const {auth, loading} = useAuth();
  const { user } = useSelector((state) => state.auth);
    
  if(loading) {
    return <p>Carregando...</p>
  }

  const checkAccessLevel = (user) => {
    if(!user) return false;
    if(user.accessLevel === 'admin') return true;
    return false;
  }
  
  return (
    <div className='app'>
      <BrowserRouter>
          <Routes>
            <Route path='/' element={auth ? <Home /> : <Navigate to='/login' />}/>
            <Route path='/login' element={auth ? <Navigate to='/' /> : <Login />}/>
            <Route
                element={!auth || !checkAccessLevel(user) ? <Navigate to='/login' /> :
                  <Dashboard>
                      <Outlet /> 
                  </Dashboard> 
                }>
                <Route path="/lista-equipo" element={<UserList />} />
                <Route path="/lista-ventas" element={<SalesList />} />
                <Route path="/lista-de-productos" element={<ProductList />} />
                <Route path="/lista-clientes" element={<CustomerList />} />
                <Route path="/agregar-producto" element={<AddProducts />} />
                <Route path="/agregar-clientes" element={<AddCustomers />} />
                <Route path="/equipo" element={<AddUser />} />
                <Route path="/editar-equipo/:id" element={<EditUser />} />
                <Route path="/editar-producto/:id" element={<EditProducts />} />
                <Route path="/editar-cliente/:id" element={<EditCustomers />} />
                <Route path="/editar-venta/:id" element={<EditSales />} />
            </Route>
            <Route path="*" element={<Home />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
