//styles
import './AddProducts.css';
import 'react-toastify/dist/ReactToastify.css';

//hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

//redux
import { registerProduct, resetMessage, resetSuccess } from "../../slices/productSlice";

//components
import Title from '../../components/Title/Title';
import SubTitle from '../../components/SubTitle/SubTitle';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import TextArea from '../../components/TextArea/TextArea';
import { ToastContainer, toast } from 'react-toastify';

const AddProducts = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { message, error, success, loading } = useSelector((state) => state.product);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [code, setCode] = useState('');
  const [barCode, setBarCode] = useState('');
  const [price, setPrice] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const barCodeInt = parseInt(barCode);
    const priceFloat = parseFloat(price);

    const product = {
      code: code, 
      barCode: barCodeInt,
      name: name, 
      price: priceFloat,
      description: description,
    }

    dispatch(registerProduct(product));
  };

  useEffect(() => {
    if(success){
      setName('');
      setDescription('');
      setBarCode('');
      setPrice('');
      setCode('');
      dispatch(resetSuccess());
    }
  }, [dispatch, success]);

  if(message){
    toast.success(message[0], {
      autoClose: 10000,
    })
    dispatch(resetMessage());
  }

  return (
    <div className='add-products'>
      <ToastContainer />
      <div className='container'>
        <Title text='Agregar Servicio' />
        <form onSubmit={handleSubmit}>
          <SubTitle text='Información' />
          <Input 
            label='Codigo:'
            type='text' 
            method={(e) => setCode(e.target.value)}
            value={code}
          />
          <Input 
            label='Nombre:'
            type='text' 
            method={(e) => setName(e.target.value)}
            value={name}
          />
          <TextArea 
            label='Descripción:'
            method={(e) => setDescription(e.target.value)}
            value={description}
          />
          <Input 
            label='Código de Barras:'
            type='number' 
            method={(e) => setBarCode(e.target.value)}
            value={barCode}
          />
          <Input 
            label='Precio:'
            type='number' 
            method={(e) => setPrice(e.target.value)}
            value={price}
          />
          <div className='buttons'>
            {!loading && <Button text='Agregar' />}
            {loading && (
              <Button
                text='Aguarde...'
                disabled
              />
            )}
            <Button
                text='Volver'
                type='button'
                method={() => navigate('/lista-de-productos')}
            />
          </div>
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default AddProducts;