//styles
import './EditUser.css';
import 'react-toastify/dist/ReactToastify.css';

//hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//redux
import { getUser, resetMessage, updateUser } from "../../slices/userSlice";

//components
import Title from '../../components/Title/Title';
import SubTitle from '../../components/SubTitle/SubTitle';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import SelectList from '../../components/SelectList/SelectList';
import { ToastContainer, toast } from 'react-toastify';

const EditUser = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const { user, message, error, loading } = useSelector((state) => state.user);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [accessLevel, setAccessLevel] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        dispatch(getUser(id));
    }, [dispatch, id]);

    useEffect(() => {
        setUsername(user.username);
        setAccessLevel(user.accessLevel);
    }, [user]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const user = {
            id: id, 
            username: username, 
            accessLevel: accessLevel,
            password: password,
            confirmPassword: confirmPassword,
        }

        dispatch(updateUser(user));
    };

    if(message){
      toast.success(message[0], {
        autoClose: 10000,
      })
      dispatch(resetMessage());
    }

  return (
    <div className='edit-user'>
      <ToastContainer />
      <div className='container'>
        <Title text='Editar Profesional' />
        <form onSubmit={handleSubmit}>
          <SubTitle text='Información' />
          <Input 
            label='Nombre:'
            type='text' 
            method={(e) => setUsername(e.target.value)}
            value={username}
          />
          <SelectList 
            label='Nivel:'
            value={accessLevel}
            method={(selected) => setAccessLevel(selected.value)}
            list={[
              {value: 'seller', label: 'Profesional'},
              {value: 'admin', label: 'Administrador'}
            ]} 
          />
          <Input 
            label='Contraseña:'
            type='password' 
            method={(e) => setPassword(e.target.value)}
            value={password}
          />
          <Input 
            label='Confirme la contraseña:'
            type='password' 
            method={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
          <div className='buttons'>
            {!loading && <Button text='Actualizar' />}
            {loading && (
              <Button
                text='Aguarde...'
                disabled
              />
            )}
            <Button
                text='Volver'
                type='button'
                method={() => navigate('/lista-equipo')}
            />
          </div>          
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default EditUser;