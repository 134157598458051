//styles
import './SalesList.css';

//hooks
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

//redux
import { getAllSales, deleteSale } from "../../slices/saleSlice";

//components
import Title from '../../components/Title/Title';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import DataTable from 'react-data-table-component';
import { format } from 'date-fns-tz';
import * as XLSX from 'xlsx';

const SalesList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { sales, reloadTable } = useSelector((state) => state.sale);
    const [records, setRecords] = useState();
    const [searchTerm, setSearchTerm] = useState('');

    const columns = [
        {
            name: 'Servicio',
            selector: row => row.productName,
            sortable: true
        },
        {
            name: 'Cantidad',
            selector: row => row.quantity,
            sortable: true
        },
        {
            name: 'Profesional',
            selector: row => row.username,
            sortable: true
        },
        {
            name: 'Precio',
            selector: row => row.realPrice,
            sortable: true
        },
        {
            name: 'Precio Venta',
            selector: row => row.price,
            sortable: true
        },
        {
            name: 'Paciente',
            selector: row => row.clientName,
            sortable: true
        },
        {
            name: 'Teléfono',
            selector: row => row.telephone,
            sortable: true
        },
        {
            name: 'Método de Pago',
            selector: row => row.paymentMethod,
            sortable: true
        },
        { 
            cell:(row) => (
                <button onClick={() => navigate("/editar-venta/"+ row.id)} className='btn-xs btn-warning'>Editar</button>
            )
        },
        { 
            cell:(row) => (
                <button onClick={() => dispatch(deleteSale(row.id))} className='btn-xs btn-danger'>Borrar</button>
            )
        }
    ];

    const exportDataToExcel = () => {
        const columnsToRemove = ['id', 'productId', 'clientId', 'userId', 'updatedAt'];
    
        const columnMappings = {
            'id': 'ID',
            'productName': 'Servicio',
            'realPrice': 'Precio',
            'price': 'Precio Venta',
            'quantity': 'Cantidad',
            'paymentMethod': 'Método Pago',
            'clientName': 'Paciente',
            'telephone': 'Teléfono',
            'username': 'Profesional',
            'createdAt': 'Data da compra',
            'updatedAt': 'Atualizado em',
        };
    
        const transformedSales = sales.map(sale => {
            const transformedSale = {};
    
            Object.keys(sale).forEach(key => {
                if (!columnsToRemove.includes(key)) {
                    const newColumnName = columnMappings[key] || key;
    
                    if (key === 'createdAt') {
                        const formattedDate = format(
                            new Date(sale[key]),
                            'yyyy-MM-dd HH:mm:ss',
                            { timeZone: 'America/Mexico_City' }
                        );
                        transformedSale[newColumnName] = formattedDate;
                    } else {
                        transformedSale[newColumnName] = sale[key];
                    }
                }
            });
    
            return transformedSale;
        });
    
        const ws = XLSX.utils.json_to_sheet(transformedSales);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(data);
    
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data_export.xlsx';
        a.click();
    
        URL.revokeObjectURL(url);
    };

    const handlerFilter = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (sales) {
            const filteredRecords = sales.filter((record) => {
                return (
                    record.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    record.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    record.username.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });

            setRecords(filteredRecords);
        }
    }, [sales, searchTerm]);

    useEffect(() => {
        dispatch(getAllSales());
        if (reloadTable)
            dispatch(getAllSales());
    }, [dispatch, reloadTable]);

    useEffect(() => {
        setRecords(sales)
    }, [sales]);

  return (
    <div className='sales-list'>
      <div className='container'>
        <Title text='Lista Ventas' />
        <div className='table'>
            <div className='search'>
                <Input 
                    label='Buscar:'
                    type='text' 
                    method={handlerFilter}
                />
                <Button 
                    text='Exportar' 
                    method={exportDataToExcel}
                />                
            </div>
            {sales &&
                <DataTable 
                   className='datatable'
                   columns={columns}
                   data={records}
                   fixedHeader
                   pagination
                ></DataTable>
            }
        </div>
      </div>
    </div>
  );
}

export default SalesList;