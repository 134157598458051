//styles
import './EditSales.css';

//hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//redux
import { getSale, updateSale, resetMessage } from "../../slices/saleSlice";
import { getAllCustomers } from "../../slices/clientSlice";
import { getAllProducts } from "../../slices/productSlice";
import { getAllUsers } from "../../slices/userSlice";

//components
import Title from '../../components/Title/Title';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import SelectList from '../../components/SelectList/SelectList';
import { ToastContainer, toast } from 'react-toastify';
import Select from '../../components/Select/Select';

const EditSales = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const { sale, message, error, loading } = useSelector((state) => state.sale);
    const { users } = useSelector((state) => state.user);
    const { customers } = useSelector((state) => state.client);
    const { products } = useSelector((state) => state.product);

    const [userId, setUserId] = useState();
    const [userList, setUserList] = useState([]);

    const [clientId, setClientId] = useState();
    const [clientList, setClientList] = useState([]);
  
    const [productId, setProductId] = useState();
    const [productList, setProductList] = useState([]);

    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState("");

    const handleProductSelectList = (products) => {
      const productList = [];
      products.map((product) => (
        productList.push({
          value: product.id,
          label: product.name
        })
      ));  
      setProductList(productList);
  } 
  
    const handleClientSelectList = (customers) => {
      const customersList = [];
      customers.map((client) => (
        customersList.push({
          value: client.id,
          label: client.name
        })
      ));  
      setClientList(customersList);
    }  

    const handleUserSelectList = (users) => {
      const usersList = [];
      users.map((user) => (
        usersList.push({
          value: user.id,
          label: user.username
        })
      ));  
      setUserList(usersList);
    } 

    useEffect(() => {
      setClientId(sale.clientId);
      setProductId(sale.productId);
      setUserId(sale.userId);
      setPrice(sale.price);
      setPaymentMethod(sale.paymentMethod);
      setQuantity(sale.quantity);
    }, [sale]);

    useEffect(() => {
      handleProductSelectList(products);
    }, [products]);

    useEffect(() => {
      handleClientSelectList(customers);
    }, [customers]);

    useEffect(() => {
      handleUserSelectList(users);
    }, [users]);

    useEffect(() => {
      dispatch(getSale(id));
    }, [dispatch, id]);

    useEffect(() => {
      dispatch(getAllProducts());
    }, [dispatch]);
  
    useEffect(() => {
      dispatch(getAllCustomers());
    }, [dispatch]);

    useEffect(() => {
      dispatch(getAllUsers());
    }, [dispatch]);

    const handleSubmit = (e) => {
      e.preventDefault();

      const sale = {
        id: id,
        userId: userId, 
        clientId: clientId,
        productId: productId,
        price: parseFloat(price),
        quantity: parseFloat(quantity),
        paymentMethod: paymentMethod,
      }
  
      dispatch(updateSale(sale));
    }

    if(message){
      toast.success(message[0], {
        autoClose: 10000,
      })
      dispatch(resetMessage());
    }

  return (
    <div className='edit-sales'>
      <ToastContainer />
      <div className='container'>
        <Title text='Editar Venta' />
        <form onSubmit={handleSubmit}>
            {clientList && 
              <SelectList 
              label='Paciente:'
              value={clientId}
              method={(selected) => setClientId(selected.value)}
              list={clientList} />
            }
            {!clientList && 
              <SelectList disabled />
            }
            {productList && 
                <SelectList 
                label='Servicio:'
                value={productId}
                method={(selected) => setProductId(selected.value)}
                list={productList} />
              }
              {!productList && 
                <SelectList disabled />
              }
            <Input
              label="Cantidad:"
              type="number"
              method={(e) => setQuantity(e.target.value)}
              value={quantity}
              required
          />
          <Input
              label="Price de Venta:"
              type="number"
              method={(e) => setPrice(e.target.value)}
              value={price}
              required
            />
            <Select
              label="Método de pago:"
              options={['Tarjeta Crédito', 'Tarjeta Debito', 'Transferencia', 'Efectivo']}
              method={(e) => setPaymentMethod(e.target.value)}
              value={paymentMethod}
            />
            {userList && 
              <SelectList 
              label='Profesional:'
              value={userId}
              method={(selected) => setUserId(selected.value)}
              list={userList} />
            }
            {!userList && 
              <SelectList disabled />
            }
              
            <div className='buttons'>
              {!loading && <Button text='Actualizar' />}
              {loading && (
                <Button
                  text='Aguarde...'
                  disabled
                />
              )}
              <Button
                text='Volver'
                type='button'
                method={() => navigate('/lista-ventas')}
              />
          </div>
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default EditSales;