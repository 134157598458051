//styles
import "./AddCustomers.css";

//hooks
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//redux
import {
  registerClient,
  resetMessage,
  resetSuccess,
} from "../../slices/clientSlice";
import {
  getAllStates,
  getAllCounties,
} from "../../slices/locationSlice";

//components
import Title from "../../components/Title/Title";
import SubTitle from "../../components/SubTitle/SubTitle";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Select from "../../components/Select/Select";
import SelectList from "../../components/SelectList/SelectList";
import { ToastContainer, toast } from "react-toastify";

const AddCustomers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { message, error, success, loading } = useSelector(
    (state) => state.client
  );
  const { states, counties } = useSelector((state) => state.location);

  const [stateList, setStateList] = useState([]);
  const [countyList, setCountyList] = useState([]);

  const [codState, setCodState] = useState("");

  const [name, setName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [evaluationPoints, setEvaluationPoints] = useState(0);

  const [state, setState] = useState("");
  const [county, setCounty] = useState("");
  const [codPostal, setCodPostal] = useState("");
  const [foundBy, setFoundBy] = useState("");

  const [indicatedBy, setIndicatedBy] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const client = {
      name: name,
      telephone: telephone,
      dateOfBirth: dateOfBirth,
      state: state,
      county: county,
      codPostal: codPostal,
      foundBy: foundBy,
      evaluationPoints: parseInt(evaluationPoints),
      indicatedBy: foundBy == "Indicación" ? indicatedBy : null,
    };

    dispatch(registerClient(client));
  };

  const handleStateSelectList = (states) => {
    const statesList = [];
    states.map((state) =>
      statesList.push({
        value: state.codState,
        label: state.name,
      })
    );
    setStateList(statesList);
  };

  const handleCountySelectList = (counties) => {
    const countiesList = [];
    counties.map((county) =>
      countiesList.push({
        value: county.name,
        label: county.name,
      })
    );
    setCountyList(countiesList);
  };

  const filterCountiesByState = (code) => {
    setCodState(code);

    const countiesFilterList = counties.filter(
      (county) => county.codState == code
    );

    const countiesList = [];

    countiesFilterList.map((county) =>
      countiesList.push({
        value: county.name,
        label: county.name,
      })
    );

    setCountyList(countiesList);
  };

  useEffect(() => {
    handleStateSelectList(states);
  }, [states]);

  useEffect(() => {
    handleCountySelectList(counties);
  }, [counties]);

  useEffect(() => {
    dispatch(getAllStates());
    dispatch(getAllCounties());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(resetSuccess());
    }
  }, [dispatch, success]);

  if (message) {
    toast.success(message[0], {
      autoClose: 10000,
    });
    dispatch(resetMessage());
  }

  return (
    <div className="add-customers">
      <ToastContainer />
      <div className="container">
        <Title text="Agregar Pacientes" />
        <form onSubmit={handleSubmit}>
          <SubTitle text="Datos paciente" />
          <Input
            label="Nombre:"
            type="text"
            method={(e) => setName(e.target.value)}
            value={name}
          />
          <Input
            label="Telefono:"
            type="text"
            method={(e) => setTelephone(e.target.value)}
            value={telephone}
          />
          <Input
            label="Fecha nacimiento:"
            type="date"
            method={(e) => setDateOfBirth(e.target.value)}
            value={dateOfBirth}
          />
          <Select
            label="Puntaje:"
            options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            method={(e) => setEvaluationPoints(e.target.value)}
            value={evaluationPoints}
          />

          <SubTitle text="Dirección" />

          {stateList && (
            <SelectList
              label="Estado:"
              value={codState}
              method={(selected) => {
                setState(selected.label);
                filterCountiesByState(selected.value);
              }}
              list={stateList}
            />
          )}
          {!stateList && <SelectList disabled />}
          {countyList && (
            <SelectList
              label="Municipio:"
              value={county}
              method={(selected) => {
                setCounty(selected.value);
              }}
              list={countyList}
            />
          )}
          {!countyList && <SelectList disabled />}
          <Input
            label="Codigo postal:"
            type="text"
            method={(e) => setCodPostal(e.target.value)}
            value={codPostal}
          />
          <Select
            label="Como conoció Éraz:"
            options={[
              "",
              "Redes sociales",
              "Algún sitio de búsqueda en internet",
              "Indicación",
              "Descubrimiento físico",
            ]}
            method={(e) => setFoundBy(e.target.value)}
            value={foundBy}
          />
          {foundBy == "Indicación" && (
            <Input
              label="Referido por:"
              type="text"
              method={(e) => setIndicatedBy(e.target.value)}
              value={indicatedBy}
            />
          )}
          <div className="buttons">
            {!loading && <Button text="Adicionar" />}
            {loading && <Button text="Aguarde..." disabled />}
            <Button
              text="Volver"
              type="button"
              method={() => navigate("/lista-clientes")}
            />
          </div>
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default AddCustomers;
